import React from "react"
import MobileAppPage from "../components/servicesPage/mobileApp/mobileAppPage";

const Mobile = () => {
  return (
    <MobileAppPage/>
  )
}

export default Mobile
