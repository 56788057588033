import React from "react"
import { withTranslation } from "react-i18next"
// import { dataTechMobileTitle } from "../../../../data/dataServices"
import "./theLastTech.scss"
import TheLastTechContent from "./theLastTechContent/theLastTechContent"

const TheLastTech = props => {
  const { t } = props
  return (
    <section className="latest-teach">
      <div className="container">
        <h2 className="teach-title">
          {t("src.pages.mobileDevelopmentPage.theLastTech.title")}
        </h2>
        <p className="teach-description">
          {t("src.pages.mobileDevelopmentPage.theLastTech.content")}
        </p>

        <TheLastTechContent />
      </div>
    </section>
  )
}

export default withTranslation()(TheLastTech)
