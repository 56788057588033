import React from "react"
import { withTranslation } from "react-i18next"
// import { dataOurMobileProcess } from "../../../../../data/dataServices"

const ContentMobile = props => {
  const { t } = props
  return (
    <>
      {t("src.pages.mobileDevelopmentPage.ourMobileProcess.data").map(
        (block, index) => (
          <div key={index.toString()} className="col">
            <header className="col-header">
              <div className={`header-title ${props.show}`}>{block.title}</div>
            </header>
            <footer className="col-footer">
              <ul className="footer-list">
                {block.content.map((ct, idx) => (
                  <li key={idx.toString()}>{ct}</li>
                ))}
              </ul>
            </footer>
          </div>
        )
      )}
    </>
  )
}

export default withTranslation()(ContentMobile)
