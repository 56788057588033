import React from "react"
import { withTranslation } from "react-i18next"
// import { dataTechMobile } from "../../../../../data/dataServices"

const Augmented = props => {
  const { t } = props
  return (
    <>
      <div className="preview" style={{ height: "160px" }}>
        <div className="teach-icon">
          <div className="icon2 iconLastTech " />
        </div>
        <h3 className="item-title">
          {t("src.pages.mobileDevelopmentPage.theLastTech.data")[1].title}
        </h3>
      </div>
      <div className="more-info">
        <h3 className="item-title">
          {t("src.pages.mobileDevelopmentPage.theLastTech.data")[1].title}
        </h3>
        <p className="item-description">
          {t("src.pages.mobileDevelopmentPage.theLastTech.data")[1].content}
        </p>
      </div>
    </>
  )
}
export default withTranslation()(Augmented)
