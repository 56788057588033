import React, { Component } from "react"
import MobilePageTitle from "./mobilePageTitle/mobilePageTitle"
import Layout from "../../layout/layout.higherComponent"
import ClientsServices from "../servicesHomePage/servicesClients/clientsServices"
import TalkToOurTeam from "../../homepageInnos/about/TalkToOurTeam"
import OurMobileServices from "./ourMobileServices/ourMobileServices"
import TheLastTech from "./theLastTech/theLastTech"
import Head from "../../head"
import OurMobileProcess from "./ourMobileProcess/ourMobileProcess"
// import Contact from "../../homepageInnos/contact/contact.component"

class MobileAppPage extends Component {
  constructor(props) {
    super(props)
    this.statsRef = React.createRef()
    this.statsRef2 = React.createRef()
  }
  handleScroll = e => {
    e.preventDefault()
    window.scrollTo({
      top: this.statsRef.current.offsetTop,
      behavior: "smooth"
    })
  }
  handleScroll2 = e => {
    e.preventDefault()
    window.scrollTo({
      top: this.statsRef2.current.offsetTop,
      behavior: "smooth"
    })
  }
  render() {
    return (
      <Layout>
        <Head title="Services" />
        <MobilePageTitle />
        <ClientsServices />
        <TalkToOurTeam />
        <OurMobileServices />
        <TheLastTech />
        <OurMobileProcess />
        {/* <div style={{ paddingTop: "70px" }}>
          <Contact />
        </div> */}
      </Layout>
    )
  }
}

export default MobileAppPage
