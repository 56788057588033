import React from "react"
import "./dotsMobile.scss"

const DotsMobile = props => {
  return (  
    <div className={`dev-team__steps`}>
      <div className={`groupDots dots1 ${props.show}`}>
        {Array(6)
          .fill(1)
          .map((block, index) => (
            <div key={index} className="dot" />
          ))}
      </div>

      <div className={`groupDots dots2 ${props.show}`}>
        {Array(6)
          .fill(1)
          .map((block, index) => (
            <div key={index} className="dot" />
          ))}
      </div>

    </div>
  )
}

export default DotsMobile
