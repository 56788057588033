import React from "react"
import { withTranslation } from "react-i18next"
// import {dataMobileTitle} from '../../../../data/dataServices'
import "./mobilePageTitle.scss"

const MobilePageTitle = props => {
  const { t } = props
  return (
    <section className="hero hero--600 bgMobile">
      <div className="container">
        <div className="hero__content">
          <h1 className="title title--uppercase">
            <span className="title__preheader title__preheader--tag">
              {t("src.pages.mobileDevelopmentPage.title")}
            </span>
            {t("src.pages.mobileDevelopmentPage.subtitle")}
          </h1>
          <p className="hero__lead">
            {t("src.pages.mobileDevelopmentPage.content")}
          </p>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(MobilePageTitle)
