import React, { useState } from "react"

import Internet from "../internet/internetMobile"
import Cloud from "../cloud/cloudMobile"
import Blockchain from "../blockchain/blockchainMobile"
import Augmented from "../augmented/augmentedMobile"
import Artificial from "../artificial/artificialMobile"
import FinTech from "../fintech/fintechMobile"

const TheLastTechContent = () => {
  const [activeDiv, setActive] = useState(`Blockchain`)

  const showBlockchain = () => {
    setActive("Blockchain")
  }
  const showAugmented = () => {
    setActive("Augmented")
  }
  const showArtificial = () => {
    setActive("Artificial")
  }
  const showInternet = () => {
    setActive("Internet")
  }
  const showCloud = () => {
    setActive("Cloud")
  }
  const showFinTech = () => {
    setActive("FinTech")
  }
  return (
    <ul className="teach-items" style={{ marginBottom: "180px" }}>
      <li
        className={`teach-item ${activeDiv === "Blockchain" ? "active" : ""}`}
        onClick={showBlockchain}
      >
        <Blockchain />
      </li>
      <li
        className={`teach-item ${activeDiv === "Augmented" ? "active" : ""}`}
        onClick={showAugmented}
      >
        <Augmented />
      </li>
      <li
        className={`teach-item ${activeDiv === "Artificial" ? "active" : ""}`}
        onClick={showArtificial}
      >
        <Artificial />
      </li>
      <li
        className={`teach-item ${activeDiv === "Internet" ? "active" : ""}`}
        onClick={showInternet}
      >
        <Internet />
      </li>
      <li
        className={`teach-item ${activeDiv === "Cloud" ? "active" : ""}`}
        onClick={showCloud}
      >
        <Cloud />
      </li>
      <li
        className={`teach-item ${activeDiv === "FinTech" ? "active" : ""}`}
        onClick={showFinTech}
      >
        <FinTech />
      </li>
    </ul>
  )
}
export default TheLastTechContent
